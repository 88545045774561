//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "./networking/api";
import common from "./common/common";
export default {
  name: "IncentiveRedpacketIndex",
  data() {
    return {
      userInfo: {},
      date: this.$moment().format("YYYY-MM-DD"),
      dialogFormVisible: false,
      storeListLoading: false,
      storesOptions: [],
      stores: [],
      curStore: {},
      storeName: "",
      storeId: "",
      imageHost:api.imageHost,
      actList:[],
      actInfo:{
        id:'',
        validTime:'',
        actionName:'',
        incentiveRedpacketStoreList:[]
      },
      actAccountInfo:{

      },
      showAct:false
    };
  },
  mounted: function () {
    window.document.title = "";
    this.requestUserInfo();
  },
  activated:function(){
    let that = this
    if(that.storeId && that.actInfo.id){
      that.getIncentiveRedPacketWalletAccount()
    }
  },
  methods: {
    requestUserInfo: function () {
      let that = this;
      this.storeListLoading = true;
      this.axios
        .get(api.getUserInfo)
        .then(function (response) {
          that.storeListLoading = false;
          if (common.success(response)) {
            let data = response.data.data;
            that.userInfo = data;

            that.getData();
          } else {
            alert("获取getUserInfo失败：" + response.data.reason);
          }
        })
        .catch(function (error) {
          alert("获取getUserInfo失败：" + error);
        })
        .then(function () {
          that.storeListLoading = false;
        });
    },
    goList(){
      this.$router.push({ path: '/incentiveRedpacket/list', query: { storeId:this.storeId,
        actionId:this.actInfo.id }});
    },
    closeAct(){
      this.showAct = false
    },
    openAct(){
      this.showAct = true
    },
    changeAct(item){
      let _this = this
      _this.actInfo = item
      _this.stores = _this.actInfo.incentiveRedpacketStoreList
      if(_this.actInfo.incentiveRedpacketStoreList && _this.actInfo.incentiveRedpacketStoreList.length == 1){
          _this.curStore = _this.actInfo.incentiveRedpacketStoreList && _this.actInfo.incentiveRedpacketStoreList[0] || {}
          _this.storeId = _this.actInfo.incentiveRedpacketStoreList && _this.actInfo.incentiveRedpacketStoreList[0] && _this.actInfo.incentiveRedpacketStoreList[0].storeId
          _this.storeName = _this.actInfo.incentiveRedpacketStoreList && _this.actInfo.incentiveRedpacketStoreList[0] && _this.actInfo.incentiveRedpacketStoreList[0].storeName
          _this.getIncentiveRedPacketWalletAccount()
          _this.dialogFormVisible = false
        }else{
           _this.curStore = ""
          _this.storeId = ""
          _this.storeName = ""
          _this.dialogFormVisible = true
        }
      this.showAct = false
    },
    filterFn(val, update, abort) {

      update(() => {
        console.log(val, update,this.storesOptions,this.stores);
        const needle = val.toUpperCase();
        this.storesOptions = this.stores.filter(function (v) {
          let result = v.storeId.toUpperCase().indexOf(needle) > -1;
          let result1 = v.storeName && v.storeName.indexOf(needle) > -1;
          if (result || result1) {
            return true;
          }
          return false;
        });
      });
    },

    handleSelectStore: function (e) {
      console.log(e,this.curStore);
      this.storeName = this.curStore.storeName;
      this.storeId = this.curStore.storeId;
      this.dialogFormVisible = false;
      this.getIncentiveRedPacketWalletAccount();
    },
    getData() {
      let _this = this
      let postData = {
          storeId:this.userInfo.guideShopCode || 'E001Z003',
          hrId:this.userInfo.hrid
      };
      this.axios
        .get(api.getIncentiveRedPacketActionList, {params:postData})
        .then((res) => {
          console.log('getIncentiveRedPacketActionList',res)
          if (res.data && res.data.code == 0) {
            res.data.data.map(v=>{
              v.validTime = _this.$moment(v.actionTimeBegin).format("yyyy/MM/DD") + '-' + _this.$moment(v.actionTimeEnd).format("yyyy/MM/DD")
            })
            this.actList = res.data.data || []
          }else{
            this.actList = []
          }
          if(this.actList.length == 0){
            this.$dialog.alert({
              message: "未参与活动",
            });
            _this.actInfo = {
              id:'',
              validTime:'',
              actionName:''
            }
            return;
          }else{
            let actInfo = res.data.data.find(v=>{
              return _this.$route.query.actionId == v.id
            }) || res.data.data[0]
              _this.actInfo = actInfo
              _this.stores = _this.actInfo.incentiveRedpacketStoreList
              _this.curStore = actInfo.incentiveRedpacketStoreList && actInfo.incentiveRedpacketStoreList[0] || {}
              _this.storeId = actInfo.incentiveRedpacketStoreList && actInfo.incentiveRedpacketStoreList[0] && actInfo.incentiveRedpacketStoreList[0].storeId
              _this.storeName = actInfo.incentiveRedpacketStoreList && actInfo.incentiveRedpacketStoreList[0] && actInfo.incentiveRedpacketStoreList[0].storeName
            if(actInfo.incentiveRedpacketStoreList && actInfo.incentiveRedpacketStoreList.length == 1){
              _this.dialogFormVisible = false
              _this.getIncentiveRedPacketWalletAccount()
            }else{
              _this.dialogFormVisible = true
            }
          }
          _this.showAct = false
        });
    },
    getIncentiveRedPacketWalletAccount() {
      let _this = this
      let postData = {
        params: {
          storeId:this.storeId,
          actionId:this.actInfo.id
        }
      };
      this.axios
        .get(api.getIncentiveRedPacketWalletAccount, postData)
        .then((res) => {
          console.log('getIncentiveRedPacketWalletAccount',res)
          if (res.data && res.data.code == 0) {
            this.actAccountInfo = res.data.data || {}
          }
        });
    },
   
   
  }
};
